<template>
	<div class="d-flex">
		<div class="mr-2 d-flex flex-column">
			<strong>{{ currentUser.name.first }}</strong>
			<small>{{ currentUser.name.last }}</small>
		</div>
		<v-menu v-model="optionsOpened" :open-on-hover="false" :close-on-click="true" :close-on-content-click="false" :offset-x="false" :offset-y="true">
			<template v-slot:activator="{ on }">
				<v-btn fab small v-on="on" class="ml-1">
					<v-avatar size="48">
						<v-img :src="currentUser.imgURL" />
					</v-avatar>
				</v-btn>
			</template>
			<v-card rounded="xl" elevation="20">
				<v-list color="transparent" dense nav rounded class="pa-0">
					<SettingsChangeLocaleForm :current-locale="currentUser.locale" />
					<v-list-item class="px-4">
						<v-list-item-icon>
							<v-icon>mdi-brightness-4</v-icon>
						</v-list-item-icon>
						<v-list-item-content>{{ $t('nav.darkTheme') }}</v-list-item-content>
						<v-list-item-action class="mx-2">
							<v-switch v-model="darkSwitch" @change="callSwitchAccountTheme()" />
						</v-list-item-action>
					</v-list-item>
				</v-list>
				<v-list-item dense class="red darken-1 px-4 py-1" :to="{ name: 'AuthLogout' }">
					<v-list-item-icon>
						<v-icon>mdi-logout</v-icon>
					</v-list-item-icon>
					<v-list-item-content>{{ $t('auth.logout') }}</v-list-item-content>
				</v-list-item>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SettingsChangeLocaleForm from '@/components/settings/SettingsChangeLocaleForm.vue'

export default {
	name: 'NavbarOptionsWidget',
	data() {
		return {
			optionsOpened: false,
			darkSwitch: true
		}
	},
	components: {
		SettingsChangeLocaleForm
	},
	computed: {
		darkTheme() {
			return this.styleTheme === 'dark'
		},
		...mapGetters({
			currentUser: 'user/currentUser',
			styleTheme: 'user/styleTheme'
		})
	},
	watch: {
		styleTheme() {
			this.darkSwitch = this.darkTheme
		}
	},
	created() {
		this.darkSwitch = this.darkTheme
	},
	methods: {
		callSwitchAccountTheme() {
			this.switchAccountTheme(!this.darkTheme)
		},
		...mapActions('settings', ['switchAccountTheme'])
	}
}
</script>
