<template>
	<v-form ref="form" v-model="valid">
		<v-select
			v-model="locale"
			:items="availableLanguages"
			:label="$t('auth.language')"
			:rules="rules.locale"
			required
			item-text="name"
			item-value="key"
			return-object
			outlined
			dense
			@input="callChangeLocale()"
		/>
	</v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsChangeLocaleForm',
	props: {
		currentLocale: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			valid: false,
			locale: null,
			rules: {
				locale: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableLanguages.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				]
			}
		}
	},
	computed: {
		...mapGetters({
			availableLanguages: 'data/availableLanguages'
		})
	},
	created() {
		this.fetchAvailableLanguages()
		this.locale = this.currentLocale
	},
	methods: {
		callChangeLocale() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changeLocale(this.locale)
		},
		...mapActions('data', ['fetchAvailableLanguages']),
		...mapActions('settings', ['changeLocale'])
	}
}
</script>
